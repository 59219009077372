import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getConexion, postConexionShared, postConexion } from 'src/contexts/Conexion';
import objFromArray from 'src/utils/objFromArray';

const initialState = {
	isLoaded: false,
	imageProfileUrl: "",
	workspaces: {
		byId: {},
		allIds: []
	},
	documents: {
		byId: {},
		allIds: []
	},
	templates: {
		byId: {},
		allIds: []
	},
	templatesDoc: {
		byId: {},
		allIds: []
	},
	pages: {
		byId: {},
		allIds: []
	},
	elements: {
		byId: {},
		allIds: []
	},
	items: {
		byId: {},
		allIds: []
	}
};

const slice = createSlice({
	name: 'finalQuestions',
	initialState,
	reducers: {
		getQuestions(state, action) {
			const board = action.payload;
			const objResponse = {
				workspaces: [...board.workspaces],
				documents: [...board.documents],
				templates: [...board.templates],
				templatesDoc: [...board.templatesDoc],
				pages: [...board.pages],
				elements: [...board.elements],
				items: [...board.items]
			}
			state.workspaces.byId = objFromArray(objResponse.workspaces);
			state.workspaces.allIds = Object.keys(state.workspaces.byId).map(Number);
			state.documents.byId = objFromArray(objResponse.documents);
			state.documents.allIds = Object.keys(state.documents.byId).map(Number);
			state.templates.byId = objFromArray(objResponse.templates);
			state.templates.allIds = Object.keys(state.templates.byId).map(Number);
			state.templatesDoc.byId = objFromArray(objResponse.templatesDoc);
			state.templatesDoc.allIds = Object.keys(state.templatesDoc.byId).map(Number);
			state.pages.byId = objFromArray(objResponse.pages);
			state.pages.allIds = Object.keys(state.pages.byId).map(Number);
			state.elements.byId = objFromArray(objResponse.elements);
			state.elements.allIds = Object.keys(state.elements.byId).map(Number);
			state.items.byId = objFromArray(objResponse.items);
			state.items.allIds = Object.keys(state.items.byId).map(Number);
			state.isLoaded = true;
			state.imageProfileUrl = board.imageProfileUrl || '';
		}
	}
});

export const reducer = slice.reducer;

export const getQuestions = (accessData: string) => async (dispatch) => {
	try {
		//const user = await getConexion('users/me');
		//localStorage.setItem("account", user ? JSON.stringify(user.plan) : '{plan:0}');
		let vecAccessData = accessData.split('&');
		let strDocId = vecAccessData.length > 0 ? vecAccessData[0].split('=') : 'n/a';
		let docId = strDocId.length > 1 ? strDocId[1] : undefined;
		const response1 = await postConexion('documents/getPlanByDocId', { docId: docId });
		localStorage.setItem("userPlan", response1 ? JSON.stringify(response1.userPlan) : '{ plan : 0 }');
		const response2 = await getConexion(`documents/getMasterGuestUser?${accessData}`);
		dispatch(slice.actions.getQuestions(response2));
	}
	catch (error) {
		console.error(error);
		return error.message;
	}
};

//Send Questions
export const sendQuestions = (objContext: any, templatePath: string, tempToken: string, notarized: boolean) => async () => {
	let pdf = new Boolean();
	if (notarized)
		pdf = true;
	else if (notarized == false || notarized == null)
		pdf = false;
	const objRespQuestions = {
		template_path: templatePath,
		context: { ...objContext },
		convert_to_pdf: pdf,
		tempToken
	}
	localStorage.setItem('accessToken', tempToken);
	const resp = await postConexionShared("arupoAws/generateTemp", objRespQuestions);
	resp['pdf'] = pdf;
	return resp;
}

export default slice;