import React, { forwardRef } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  Tooltip,
  Fab,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  makeStyles
} from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MarkunreadMailboxTwoToneIcon from '@material-ui/icons/MarkunreadMailboxTwoTone';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  fab: {
    margin: theme.spacing(2),
  },
  underline: {
    maxWidth: '30%',
    margin: '-2% 35% 3% 35%',
  },
  copyRight: {
    color: theme.palette.action.active
  },
  marginCopy: {
    marginLeft: 0
  },
  margin: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'green !important',
      color: 'red !important'
    }
  },
  copyright: {
    fontSize: '0.6rem'
  }
}));

const Footer: FC<any> = forwardRef(({ className, ...rest }, ref) => {
  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Box mt={3}>
          <Grid
            container
            direction="row"
            justify="center"
            spacing={3}
          >
            <Grid container direction="column"
              justify="center"
              alignItems="center" item md={4} xs={12}>
              <Typography
                variant="overline"
                color="secondary"
              >
                Conecta con nosotros
              </Typography>
              <div>
                <Tooltip title="Síguenos en YouTube" aria-label="youtube">
                  <Fab target='_blank' href="https://www.youtube.com/channel/UCVxoND-BYx0UBfUxy6VMl7A" size="small" color="primary" className={classes.fab}>
                    <YouTubeIcon fontSize="default" />
                  </Fab>
                </Tooltip>
                <Tooltip title="Síguenos en Instagram" aria-label="instagram">
                  <Fab target='_blank' href="https://instagram.com/arupo.io?utm_medium=copy_link" size="small" color="secondary" className={classes.fab}>
                    <InstagramIcon fontSize="default" />
                  </Fab>
                </Tooltip>
                <Tooltip title="Síguenos en LinkedIn" aria-label="linkedin">
                  <Fab target='_blank' href="https://www.linkedin.com/company/arupo-legaltech-innovation-hub" size="small" color="primary" className={classes.fab}>
                    <LinkedInIcon fontSize="default" />
                  </Fab>
                </Tooltip>
              </div>
              <div>
                <Link
                  href="/contacto"
                  variant="overline"
                  color="textSecondary"
                >
                  Contáctanos
                </Link>
              </div>
            </Grid>
            <Grid container direction="column" alignItems="center"
              item md={4} xs={12}>
              <Typography
                variant="overline"
                color="secondary"
              >
                Houston tenemos un problema
              </Typography>
              <div>
                <Link
                  href="/policies/terms"
                  variant="overline"
                  color="textSecondary"
                >
                  Términos y condiciones
                </Link>
              </div>
              <div>
                <Link
                  href="/policies/privacy"
                  variant="overline"
                  color="textSecondary"
                >
                  Política de privacidad
                </Link>
              </div>
              <div>
                <Link
                  href="/policies/privacy-addin"
                  variant="overline"
                  color="textSecondary"
                >
                  Política de privacidad ADD-IN
                </Link>
              </div>
              <div>
                <Link
                  href="/policies/privacy-addon"
                  variant="overline"
                  color="textSecondary"
                >
                  Política de privacidad ADD-ON
                </Link>
              </div>
            </Grid>
            <Grid container direction="column"
              alignItems="center" item md={4} xs={12}>
              <Typography
                variant="overline"
                color="secondary"
              >
                Suscríbete
              </Typography>
              <div>
                <FormControl className={classes.margin} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-amount">Suscríbete</InputLabel>
                  <OutlinedInput
                    startAdornment={<InputAdornment position="start"><MarkunreadMailboxTwoToneIcon /></InputAdornment>}
                    labelWidth={80}
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box mt={3} mx={4}>
          <Grid
            container
            direction="row"
            spacing={3}
          >
            <Typography
              className={classes.copyright}
              variant="caption"
              color="textSecondary"
              align="center"
            >
              <strong>Copyright 2021® ARUPO.</strong> Todos los derechos reservados. ARUPO solo proporciona
              información y software. ARUPO no es un "servicio de referencia de abogados" o
              un "Legal Marketplace" y no brinda asesoramiento legal ni participa en ninguna
              representación legal. ARUPO no es una firma de abogados o un sustituto de un
              abogado o firma de abogados. El uso de ARUPO está sujeto a nuestros Términos y
              Condiciones y Acuerdo de privacidad.
            </Typography>
          </Grid>
        </Box>
      </Container>
    </div>
  );
});

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
