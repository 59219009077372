import React, { forwardRef } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Hidden,
  Grid,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 60,
    paddingBottom: 60,
    '& dt': {
      marginTop: theme.spacing(2)
    }
  },
  fontTitle: {
    fontSize: '2rem',
  },
  boxMargin: {
    marginTop: '-10%',
  },
  underline: {
    maxWidth: '30%',
    margin: '-3% 35% 3% 35%',
  },
  tail: {
    margin: '0% 0% -40% 5%',
  },
  leftColumn: {
    paddingRight: '30%',
    textAlign: 'right',
  },
  rightColumn: {
    paddingLeft: '25%',
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8)
  },
  resposinve: {
    [theme.breakpoints.up('lg')]: {
      paddingTop: 150,
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: "12%",
    }
  }
}));

const tailData = [
  {
    title: "Envía el requerimiento",
    description: "Indícanos que quieres automatizar y cómo quieres hacerlo.",
    image: "/static/images/hojas_mail.svg"
  },
  {
    title: "Recibe tu documento automatizado",
    description: "¡Obtén tu documento automatizado listo para compartir y usar! Tienes la aprobación final de tu automatización y estaremos encantados de realizar modificaciones.",
    image: "/static/images/hojas_rocket.svg"
  },
  {
    title: "Recibe un estimado",
    description: "Te enviaremos un estimado total por la automatización. Con Arupo podrás ahorrar mucho dinero en horas de trabajo.",
    image: "/static/images/hojas_quote.svg"
  },
  {
    title: "Vuela con tu servicio automatizado",
    description: "Ofrece a tus clientes y equipos acceso a tus documentos automatizados, para minimizar errores, ganar tiempo y dinero.",
    image: "/static/images/hojas_support.svg"
  }
]

const FAQS: FC<any> = forwardRef(({ className, ...rest }, ref) => {
  const classes = useStyles();
  const settings = window.localStorage.getItem('settings') || 'light';

  return (
    <div
      ref={ref}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
          className={classes.fontTitle}
        >
          DESARROLLADOR
        </Typography>
        <div className={classes.underline}>
          <img
            title="Arupo hub decoración borde rosa"
            alt="Arupo hub decoración borde rosa"
            src="/static/images/arupo_logos/SUBRAYADO_ROSA.svg"
          />
        </div>
        <Hidden smDown>
          <div className={classes.tail}>
            <img
              title="Arupo hub decoración tallo"
              alt="Arupo hub decoración tallo"
              src={settings.includes("ONE_DARK") ? "/static/images/tallo_oscuro.svg" : "/static/images/tallo_claro.svg"}
            />
          </div>
          <Grid
            container
            spacing={3}
            component="dl"
            className={classes.boxMargin}
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <Box mt={3}>
                <dt>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    className={classes.leftColumn}
                  >
                    Envía el requerimiento
                </Typography>
                </dt>
                <dd>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.leftColumn}
                  >
                    Indícanos que quieres automatizar y cómo quieres hacerlo.
                </Typography>
                </dd>
              </Box>
              <Box mt={3}>
                <dt>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                  >
                    ⠀{/* . */}
                  </Typography>
                </dt>
                <dd>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    ⠀{/* . */}
                  </Typography>
                </dd>
              </Box>
              <Box mt={3}>
                <dt>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    className={classes.leftColumn}
                  >
                    Recibe tu documento automatizado
                </Typography>
                </dt>
                <dd>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.leftColumn}
                  >
                    ¡Obtén tu documento automatizado listo para compartir y usar! Tienes la aprobación final de tu automatización y estaremos encantados de realizar modificaciones.
                </Typography>
                </dd>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <Box mt={3}>
                <dt>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                  >
                    ⠀{/* . */}
                  </Typography>
                </dt>
                <dd>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    ⠀{/* . */}
                  </Typography>
                </dd>
              </Box>
              <Box mt={3}>
                <dt>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    className={classes.rightColumn}
                  >
                    Recibe un estimado
                </Typography>
                </dt>
                <dd>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.rightColumn}
                  >
                    Te enviaremos un estimado total por la automatización. Con Arupo podrás ahorrar mucho dinero en horas de trabajo.
                </Typography>
                </dd>
              </Box>
              <Box mt={3}>
                <dt>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    className={classes.rightColumn}
                  >
                    Vuela con tu servicio automatizado
                </Typography>
                </dt>
                <dd>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.rightColumn}
                  >
                    Ofrece a tus clientes y equipos acceso a tus documentos automatizados, para minimizar errores, ganar tiempo y dinero.
                </Typography>
                </dd>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Box mt={8}>
            {tailData.map((data, i) => (
              <Box key={`list item ${i}`} mt={6}>
                <Grid
                  container
                  direction={i % 2 == 0 ? "row" : "row-reverse"}
                  justify="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item md={10} sm={10} xs={9}>
                    <div>
                      <Typography
                        variant="h4"
                        color="textPrimary"
                      >
                        {data.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                      >
                        {data.description}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={2} sm={2} xs={3}>
                    <img
                      title="Arupo hub imagen"
                      alt="Arupo hub imagen"
                      src={data.image}
                      className={classes.large}
                    />
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        </Hidden>
        <Box className={classes.resposinve}>
          <Box mt={6}>
            <Typography
              variant="h3"
              align="center"
              color="secondary"
            >
              Contrata un desarrollador
          </Typography>
          </Box>
          <Box
            mt={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              color="secondary"
              component="a"
              href="contacto/desarrollador"
              variant="contained"
            >
              déjanos tus datos
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
});

FAQS.propTypes = {
  className: PropTypes.string
};

export default FAQS;
