import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as interviewReducer } from 'src/views/portal/users/BridgeInterview';
import { reducer as finalQuestionsReducer } from 'src/views/portal/finalClient/BridgeFinalQuestions';
import { reducer as faqReducer } from 'src/views/portal/users/BridgeFAQ';
import { reducer as plansReducer } from 'src/views/bridges/BridgePlans';
import { reducer as donatedAccountsReducer } from 'src/views/account/BridgeDonatedAccounts';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  interview: interviewReducer,
  finalQuestions: finalQuestionsReducer,
  FAQ: faqReducer,
  plans: plansReducer,
  donatedAccounts: donatedAccountsReducer
});

export default rootReducer;