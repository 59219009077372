import React from 'react';
import type { FC } from 'react';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  createStyles,
  Theme,
  makeStyles
} from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    card: {
      width: '80%'
    },
    cover: {
      width: '100%'
    }
  }),
);

const OptionsView: FC<any> = ({ desc }) => {
  const classes = useStyles();

  return (
    <Grid
      className={classes.root}
      container
      spacing={3}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Card className={classes.card}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
          >
            <Box m={6}>
              <CardContent>
                <Typography component="h3" variant="h2">
                  {desc.title}
                </Typography>
                <Box mt={3}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    {desc.titleTags}
                  </Typography>
                  <Box mt={1}>
                    {desc.tags.map((tag) => (
                      <Chip
                        key={tag}
                        variant="outlined"
                        label={tag}
                      />
                    ))}
                  </Box>
                </Box>
                <Box mt={3}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    Description
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    {desc.descript}
                  </Typography>
                </Box>
              </CardContent>
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
            container
            alignItems="center"
            justify="center"
          >
            <CardMedia
              component="img"
              className={classes.cover}
              alt="Descripción de herramientas"
              image={desc.image}
              title="Herramientas de arupo"
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default OptionsView;
