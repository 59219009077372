import React, { useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Menu,
  MenuItem,
  IconButton,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    paddingTop: theme.spacing(2)
  },
  popover: {
    width: 250,
    left: '5%'
  }
}));

const Contacts: FC = () => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState<boolean>(false);
  const refContact = useRef<any>(null);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title="Contáctanos" ref={refContact}>
        <IconButton
          className={classes.icon}
          color="inherit"
          onClick={handleOpen}
        >
          <SvgIcon fontSize="default">
            <ContactPhoneOutlinedIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={refContact.current}
        open={isOpen}
      >
        <MenuItem
          component={RouterLink}
          to="/contacto/desarrollador"
        >
          Contrata un desarrollador
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/contacto"
        >
          Contáctanos
        </MenuItem>
      </Menu>
    </>
  );
};

export default Contacts;
