import React, { useEffect, useRef } from 'react';
import type { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import Features from './Features';
import Testimonials from './Testimonials';
import DescriptionDev from './DescriptionDev';
import CTA from './CTA';
import FAQS from './FAQS';
import Footer from './Footer';

const useStyles = makeStyles(() => ({
  root: {}
}));

const HomeView: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { active_section } = useParams<{ active_section: string }>();
  const homeRef = useRef<HTMLInputElement | null>(null);
  const exploraRef = useRef<HTMLInputElement | null>(null);
  const herramientasRef = useRef<HTMLInputElement | null>(null);
  const preciosRef = useRef<HTMLInputElement | null>(null);
  const desarrolladorRef = useRef<HTMLInputElement | null>(null);

  const handleScroll = (section: string): void => {
    switch (section) {
      case "#home":
        homeRef.current.scrollIntoView({ behavior: "smooth" })
        break;
      case "#explora":
        exploraRef.current.scrollIntoView({ behavior: "smooth" })
        break;
      case "#herramientas":
        herramientasRef.current.scrollIntoView({ behavior: "smooth" })
        break;
      case "#precios":
        preciosRef.current.scrollIntoView({ behavior: "smooth" })
        break;
      case "#desarrollador":
        desarrolladorRef.current.scrollIntoView({ behavior: "smooth" })
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (active_section === "desarrollador")
      handleScroll(`#${active_section}`)
  }, [desarrolladorRef])

  useEffect(() => {
    return history.listen((location) => {
      handleScroll(location.hash);
    })
  }, [history])

  return (
    <Page
      className={classes.root}
      title="Home Arupo Legaltech Transformación digital de tu negocio"
    >
      <Hero ref={homeRef} />
      <Features ref={exploraRef} />
      <Testimonials ref={herramientasRef} />
      <CTA ref={preciosRef} />
      <FAQS ref={desarrolladorRef} />
      <DescriptionDev />
      <Footer />
    </Page>
  );
};

export default HomeView;
