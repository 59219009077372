import React, { forwardRef } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import CarouselImg from 'src/components/CarouselImg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 60,
    paddingBottom: 60
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },

  fontTitle: {
    fontSize: '2rem',
  },
  atomo: {
    margin: '-2% 20% -2% 20%',
  },
  underline: {
    maxWidth: '30%',
    margin: '-3% 35% 0% 35%'
  },
  card: {
    width: '80%'
  }
}));

const Features: FC<any> = forwardRef(({ className, ...rest }, ref) => {
  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
          className={classes.fontTitle}
        >
          EXPLORA
        </Typography>
        <div className={classes.underline}>
          <img
            title="Arupo hub decoración borde rosa"
            alt="Arupo hub decoración borde rosa"
            src="/static/images/arupo_logos/SUBRAYADO_ROSA.svg"
          />
        </div>
        <Box mt={6}>
          <Grid
            container
            spacing={3}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <CarouselImg />
          </Grid>
        </Box>
      </Container>
    </div>
  );
});

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
