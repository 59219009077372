import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getConexion, postConexion, deleteConexion } from 'src/contexts/Conexion';
import objFromArrayFaq from 'src/utils/objFromArrayFaq';

const initialState = {
  isLoaded: false,
  faqLists: {
    byId: {},
    allIds: []
  }
};

const slice = createSlice({
  name: 'FAQ',
  initialState,
  reducers: {
    getBoard(state, action) {
      const board = action.payload;
      state.faqLists.byId = objFromArrayFaq(board.Message);
      state.faqLists.allIds = Object.keys(state.faqLists.byId).map(Number);
      state.isLoaded = true;
    },
    createFaq(state, action) {
      const faqList = action.payload;
      state.faqLists.byId[faqList.faqId] = faqList;
      state.faqLists.allIds.push(faqList.faqId);
    },
    deleteWorkspace(state, action) {
      const { faqId } = action.payload;
      state.faqLists.byId = _.omit(state.faqLists.byId, parseInt(faqId));
      _.pull(state.faqLists.allIds, parseInt(faqId));
    },
    updateFaq(state, action) {
      const faqList = action.payload;
      state.faqLists.byId[faqList.faqId] = faqList;
    }
  }
});

export const reducer = slice.reducer;

export const getBoard = () => async (dispatch) => {
  const response = await getConexion("faq/getFaq");
  dispatch(slice.actions.getBoard(response));
};

export const createFaq = (newValue: any, pos: number) => async (dispatch) => {
  delete newValue["submit"];
  const objNewFaq = { ...newValue, position: pos };
  const { Message } = await postConexion("faq/createFaq", objNewFaq);
  const objResponse = { ...objNewFaq, faqId: Message.value.faq_id }
  dispatch(slice.actions.createFaq(objResponse));
};

export const deleteFaq = (faqId: string) => async (dispatch) => {
  const objFaqDelete = {
    faq_id: parseInt(faqId)
  }
  await deleteConexion("faq/deleteFaq", objFaqDelete);
  dispatch(slice.actions.deleteWorkspace({ faqId }));
};

export const updateFaq = (newValue: string, update: any, option: string) => async (dispatch) => {
  let objFaqUpdate = { ...update };
  switch (option) {
    case 'question':
      objFaqUpdate = { ...update, question: newValue };
      break;
    case 'answer':
      objFaqUpdate = { ...update, answer: newValue };
  }
  await postConexion("faq/updateFaq", objFaqUpdate);
  dispatch(slice.actions.updateFaq(objFaqUpdate));
};

export default slice;