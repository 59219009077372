/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import type { Theme } from 'src/theme';
import Logo from 'src/components/Logo';
import LogoDark from 'src/components/LogoDark';
import NavItem from './NavItem';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: 'Navbar',
    items: [
      {
        title: 'Home',
        icon: HomeOutlinedIcon,
        href: '#home'
      },
      {
        title: 'Explora',
        icon: ExploreOutlinedIcon,
        href: '#explora'
      },
      {
        title: 'Herramientas',
        icon: BuildOutlinedIcon,
        href: "#herramientas"
      },
      {
        title: 'Precios',
        icon: AttachMoneyOutlinedIcon,
        href: "#precios"
      },
      {
        title: 'Desarrollador',
        icon: CodeOutlinedIcon,
        href: "#desarrollador"
      }
    ]
  }
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const settings = window.localStorage.getItem('settings') || 'LIGHT';

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Hidden lgUp>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <Link href="#home">
              {settings.includes("LIGHT") ? <Logo /> : <LogoDark />}
            </Link>
          </Box>
          <Divider />
          <Box p={2}>
            {sections.map((section) => (
              <List key={section.subheader} >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname
                })}
              </List>
            ))}
          </Box>
          <Divider />
          <Box p={2}>
            <Box
              p={2}
              borderRadius="borderRadius"
              bgcolor="background.dark"
            >
              <Typography
                variant="h6"
                color="textPrimary"
              >
                Necesitas ayuda?
            </Typography>
              <Link
                color="primary"
                variant="subtitle1"
                component={RouterLink}
                to="/contacto/desarrollador"
              >
                Contrata un desarrollador
            </Link>
            </Box>
          </Box>
        </PerfectScrollbar>
      </Box>
    </Hidden>
  );

  return (
    <Hidden lgUp>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>
    </Hidden>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
