import React, { forwardRef } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Container,
  Typography,
  makeStyles, Grid
} from '@material-ui/core';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    backgroundImage: `url(/static/images/arupo_logos/ARBOL_OPACIDAD.svg)`,
    paddingTop: 60,
    paddingBottom: 60
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  },
  fontTitle: {
    fontSize: '2rem',
  },
  subTitleMargin: {
    margin: '-5% 0% 20% 0%'
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  underline: {
    maxWidth: '30%',
    margin: '-3% 35% 0% 35%',
  },
}));

const Testimonials: FC<any> = forwardRef(({ className, ...rest }, ref) => {
  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
          className={classes.fontTitle}
        >
          HERRAMIENTAS
        </Typography>
        <div className={classes.underline}>
          <img
            title="Arupo hub decoración borde rosa"
            alt="Arupo hub decoración borde rosa"
            src="/static/images/arupo_logos/SUBRAYADO_ROSA.svg"
          />
        </div>
        <Box mt={8}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  01
                </Avatar>
                <Box ml={2}>
                  <Typography
                    component="h3"
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Crea tu usuario
                  </Typography>
                  <Box mt={3}>

                    <Typography
                      variant="body1"
                      color="textPrimary"
                    >
                      Con ARUPO, dedica menos tiempo a revisar y redactar documentos de rutina, lo que le permite concentrarte en el trabajo más importante.
                      Si estás cansado de las constantes solicitudes para preparar un documento legal o revisar un contrato de servicios, prueba ARUPO y únete
                      a la transformación digital.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  02
                </Avatar>
                <Box ml={2}>
                  <Typography
                    component="h3"
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Diseña tu documento
                  </Typography>
                  <Box mt={3}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                    >
                      Diseña formularios que recopilan información para usarla en los documentos, construye y personaliza templates inteligentes con lógica condicional.
                      Puedes establecer pre-condiciones con un Add-In de Word.
                      Tienes la oportunidad de diseñar templates que bajo tu supervisión empoderen y se acomoden a las necesidades de tus clientes.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  03
                </Avatar>
                <Box ml={2}>
                  <Typography
                    component="h3"
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Administra y comparte
                  </Typography>
                  <Box mt={3}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      gutterBottom
                    >
                      Desde tu propio dashboard revisa y comparte con tu equipo y clientes los documentos que hayas automatizado.
                      Podrás compartirlo directamente mediante URL o podrás publicar el link dentro de tu propia página web.
                      Con la información necesaria ingresada, tus clientes o equipos recibirán sus documentos en formato PDF o Docx.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
});

Testimonials.propTypes = {
  className: PropTypes.string
};

export default Testimonials;
