/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Video as VideoIcon,
  Home as HomeIcon,
  HelpCircle as HelpIcon,
  Book as BookIcon
} from 'react-feather';
import type { Theme } from 'src/theme';
import Logo from 'src/components/Logo';
import LogoDark from 'src/components/LogoDark';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: 'Sandbox',
    items: [
      {
        title: 'Home',
        icon: HomeIcon,
        href: '/app/users/overview'
      },
      {
        title: 'Nuevo workspace',
        icon: EditIcon,
        href: '/app/users/overview/modal_on'
      },
      {
        title: 'Tutoriales',
        icon: VideoIcon,
        href: '/app/users/tutoriales'
      },
      {
        title: 'FAQ',
        icon: HelpIcon,
        href: '/app/users/FAQ',
      }
    ]
  },
  {
    subheader: 'Soporte',
    items: [
      {
        title: 'Documentación',
        icon: BookIcon,
        items: [
          {
            title: 'Registro y sesiones',
            href: '/app/users/guide/session'
          },
          {
            title: 'Actualización de datos',
            href: '/app/users/guide/update-data'
          },
          {
            title: 'Manejo de cuentas operadoras',
            href: '/app/users/guide/op-accounts'
          },
          {
            title: 'Sandbox',
            href: '/app/users/guide/sandbox'
          },
          {
            title: 'Manejo de documentos',
            href: '/app/users/guide/documents'
          },
          {
            title: 'Manejo de entrevistas',
            href: '/app/users/guide/blocks'
          },
          {
            title: 'Manejo de templates',
            href: '/app/users/guide/templates'
          },
          {
            title: 'Ejecutar entrevistas',
            href: '/app/users/guide/interview'
          },
          {
            title: 'Contáctanos',
            href: '/app/users/guide/contact'
          }
        ]
      }
    ]
  }
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  capitalLetter: {
    textTransform: 'capitalize'
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const settings = window.localStorage.getItem('settings') || 'LIGHT';

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              {settings.includes("LIGHT") ? <Logo /> : <LogoDark />}
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.imageProfileUrl || user.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              Tu suscripción:&nbsp;
              <Link
                component={RouterLink}
                to="/app/account/Subscripción"
                color="primary"
                className={classes.capitalLetter}
              >
                {user.tier}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Descarga el complemento
            </Typography>
            <Link
              color="primary"
              variant="subtitle1"
              target="_blank"
              href="https://appsource.microsoft.com/en-us/product/office/WA200002675"
            >
              Word Add-In
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              Ó usa el editor en línea
            </Typography>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
