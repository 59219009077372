import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Grid,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Avatar,
  Typography
} from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import type { Theme } from 'src/theme';

interface CarouselImgProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  card: {
    width: '100%'
  },
  header: {
    textAlign: "center"
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    marginRight: theme.spacing(1),
    height: '120%',
    width: '120%'
  },
  title: {
    lineHeight: 1.6
  },
  carousel: {
    width: "90%",
    '& button': {
      opacity: 0.3,
      padding: 1
    }
  },
  cardImage: {
    width: '85%'
  }
}));

const CarouselImg: FC<CarouselImgProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const items = [
    {
      name: "Construye, automatiza, diseña y aplica lógica en elaborados flujos y documentos para evitar:",
      avatar: "/static/images/arupo_logos/MISION.svg",
      description: [
        "Ingresar información repetitiva más de una vez",
        "Buscar cláusulas y la aplicar redacción correcta cada vez que se prepara un nuevo documento.",
        "Levantar la información necesaria para redactar cada documento de forma manual e ineficiente.",
        "Desperdiciar tiempo en correcciones documentales de tus equipos o clientes."
      ],
      image: "/static/images/arupo_logos/ATOMO1.svg"
    },
    {
      name: " Arupo te permite transformar digitalmente tu negocio. Administra un “SandBox” de cliente en el que podrás revisar tus flujos y documentos automatizados y podrás:",
      avatar: "/static/images/arupo_logos/OBJETIVES.svg",
      description: [
        "Modificarlos y editarlos.",
        "Agregar o eliminar bloques de acción en los flujos.",
        "Navegar y decidir que herramientas utilizar.",
        "Cambiar la forma en la que trabajas, reorganizando tu tiempo y operarando más barato, más rápido y mejor."
      ],
      image: "/static/images/arupo_logos/ATOMO2.svg"
    }
  ]

  const Item = ({ item }) => {
    return (
      <Card raised className={classes.card}>
        <Divider />
        <Grid
          container
          spacing={3}>
          <Grid
            item
            md={6}
            sm={6}
            xs={12}>
            <Box ml={3} mr={3}>
              <CardHeader
                className={classes.header}
                title={
                  <FormControlLabel
                    control={
                      <Avatar
                        className={classes.avatar}
                        src={item.avatar}
                      />
                    }
                    label={
                      <Typography
                        variant="h4"
                        gutterBottom
                        color="textPrimary"
                      >
                      </Typography>
                    }
                  />
                } />
              <>
                <Typography
                  align="justify" variant="h4" gutterBottom color="textPrimary">
                  {item.name}
                </Typography>
              </>
              <Grid
                container
                alignItems="center"
                justify="center">
                <CardContent>
                  <List dense={true} >
                    {item.description.map((desc, i) =>
                      <ListItem key={`list item ${i}`}>
                        <>º&nbsp;</><ListItemText
                          primary={
                            <Typography align="justify" variant="h4" color="textPrimary">
                              {desc}
                            </Typography>
                          }
                        />
                      </ListItem>
                    )}
                  </List>
                </CardContent>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
            container
            alignItems="center"
            justify="center"
          >
            <CardMedia
              component="img"
              alt="Descripción de herramientas"
              image={item.image}
              title="Herramientas de arupo"
              className={classes.cardImage}
            />
          </Grid>
        </Grid>
      </Card>
    )
  }

  return (
    <Carousel
      className={classes.carousel}
      autoPlay={true}
      interval={10000}
      animation={"fade"}
      indicators={true}
      timeout={500}
      navButtonsAlwaysVisible={true}
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
};

CarouselImg.propTypes = {
  className: PropTypes.string
};

export default CarouselImg;
