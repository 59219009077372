import React, { forwardRef, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Divider,
  Box,
  Container,
  Typography,
  makeStyles,
  Tab,
  Tabs
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import OptionsView from './DevTabs/OptionsView';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 60,
    paddingBottom: 60
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  },
  fontTitle: {
    fontSize: '2rem',
  },
  subTitleMargin: {
    margin: '-5% 0% 20% 0%'
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    margin: '-5% 0% 20% 0%'
  },
  underline: {
    maxWidth: '30%',
    margin: '-3% 35% 0% 35%',
  },
  tabCust: {
    justifyContent: "center"
  },
  scroller: {
    flexGrow: 0
  }
}));

const allDescription = [
  {
    nameTab: "Ejemplo 1",
    title: "Memorando laboral ",
    titleTags: "Precio por desarrollo de automatización",
    tags: ["$ 150"],
    descript: `Automatiza los llamados de atención a los empleados. 
    Con el documento automatizado podrás garantizar que tus clientes 
    puedan crear sus propios documentos legales bajo tu supervición.`,
    image: "/static/images/example1.svg"
  },
  {
    nameTab: "Ejemplo 2",
    title: "Transferencia de Marcas",
    titleTags: "Precio por desarrollo de automatización",
    tags: ["$ 650"],
    descript: `Automatiza el contrato con el cual se cede o transfiere a un tercero la 
    titularidad sobre signos distintivos  y marcas a cambio del pago de un precio o de 
    forma gratuita. Con el documento automatizado podrás garantizar que tus clientes puedan
    crear sus propios documentos legales bajo tu supervición. `,
    image: "/static/images/example2.svg"
  },
  {
    nameTab: "Ejemplo 3",
    title: "Compra/Venta de Vehículos",
    titleTags: "Precio por desarrollo de automatización",
    tags: ["$ 750"],
    descript: `Automatiza un contrato para formalizar la compraventa de un vehículo. Con 
    el documento automatizado podrás garantizar que tus clientes puedan crear sus propios 
    documentos legales bajo tu supervición. `,
    image: "/static/images/car.svg"
  }
]

const DescriptionDev: FC<any> = forwardRef(({ className, ...rest }, ref) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [currentDesc, setCurrentDesc] = useState<any>(allDescription[0]);

  const handleTabsChange = (event: ChangeEvent<{}>, value: number): void => {
    setCurrentTab(value);
    setCurrentDesc(allDescription[value]);
  };

  return (
    <div
      ref={ref}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
          className={classes.fontTitle}
        >
          Ejemplos de automatización
        </Typography>
        <div className={classes.underline}>
          <img
            title="Arupo hub decoración borde rosa"
            alt="Arupo hub decoración borde rosa"
            src="/static/images/arupo_logos/SUBRAYADO_ROSA.svg"
          />
        </div>
        <Box mt={3}>
          <Tabs
            classes={{ root: classes.tabCust, scroller: classes.scroller }}
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="secondary"
            value={currentTab}
            variant="scrollable"
          >
            {allDescription.map((tab, i) => (
              <Tab
                key={`tab ${i}`}
                value={i}
                label={tab.nameTab}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === 0 && <OptionsView desc={currentDesc} />}
          {currentTab === 1 && <OptionsView desc={currentDesc} />}
          {currentTab === 2 && <OptionsView desc={currentDesc} />}
          {currentTab === 3 && <OptionsView desc={currentDesc} />}
        </Box>
      </Container>
    </div>
  );
});

DescriptionDev.propTypes = {
  className: PropTypes.string
};

export default DescriptionDev;
