import axios from 'axios';

const initData = {
  // api: "https://api-staging.arupo.io/api/v1/"
  // api: "http://localhost:5000/api/v1/"
  api: "https://api-prod.arupo.io/api/v1/"
}

export const postConexionShared = async (urlApi, objeto) => {
  try {
    let url = initData.api + urlApi;
    let auth = {
      headers: { 'Authorization': localStorage.getItem("accessToken") }
    }
    const response = await axios.post(url, objeto, auth).catch(err => {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            if (err.response.data.message === "Token does not exists.")
              throw new Error(`No hay permiso para actualizar tu contraseña`);
            else
              throw new Error(`Tu correo o contraseña son incorrectos`);
          case 403:
            localStorage.setItem("accessToken", null);
            throw new Error(`La autorización ha caducado, intenta de nuevo`);
          case 401:
            const originalRequest = err.config;
            let refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken)
              return axios
                .post(initData.api + "users/token/refresh", { refreshToken: refreshToken })
                .then((res) => {
                  if (res.status === 200) {
                    localStorage.setItem("accessToken", res.data.accessToken);
                    return axios(originalRequest);
                  }
                });
            break;
          case 404:
            throw new Error(`Su correo o contraseña son incorrectos`);
          case 409:
            if (err.response.data.message === "Email address not valid")
              throw new Error(`Correo electrónico no válido`);
            else
              throw new Error(`El usuario ya poseé una cuenta`);
          case 500:
            if (err.response.data.message === "Maybe the user is disabled.")
              throw new Error(`Tu cuenta se encuentra desactivada`);
            else if (err.response.data.message === "Probably the document was disbled by admin.")
              throw new Error(`El documento se encuentra desactivado.`);
            else
              throw new Error(`Por favor intente de nuevo, más tarde`);
          default:
            throw new Error(`La api responde pero con error ${err.response.status}`);
        }
      }
      else if (err.request) {
        throw new Error(`No existe conexión`);
      }
      else {
        throw err;
      }
    });
    const resp = response.data;
    return resp;
  }
  catch (err) {
    if (err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
      err.message === "La autorización ha caducado, intenta de nuevo")
      window.location.reload();
    return Promise.reject((err.message && err) || 'Something went wrong');
  }
}

export const postConexionFileShared = async (urlApi, file, type?) => {
  try {
    let url = initData.api + urlApi;
    let formData = new FormData();
    formData.append("file", file[0]);
    if (type)
      formData.append("type", type);
    if (localStorage.getItem("accessToken"))
      formData.append("mode", localStorage.getItem("accessToken"));
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': localStorage.getItem("accessToken"),
        'mode': localStorage.getItem("accessToken")
      }
    }).catch(err => {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            throw new Error(`Su correo o contraseña son incorrectos`);
          case 401:
            const originalRequest = err.config;
            let refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken)
              return axios
                .post(initData.api + "users/token/refresh", { refreshToken: refreshToken })
                .then((res) => {
                  if (res.status === 200) {
                    localStorage.setItem("accessToken", res.data.accessToken);
                    return axios(originalRequest);
                  }
                });
            break;
          case 403:
            localStorage.setItem("accessToken", null);
            throw new Error(`La autorización ha caducado, intenta de nuevo`);
          case 404:
            throw new Error(`Su correo o contraseña son incorrectos`);
          case 409:
            throw new Error(`El usuario ya poseé una cuenta`);
          case 500:
            throw new Error(`Por favor intente de nuevo, más tarde`);
          default:
            throw new Error(`La api responde pero con error ${err.response.status}`);
        }
      }
      else if (err.request) {
        throw new Error(`No existe conexión`);
      }
      else {
        throw err;
      }
    });
    const resp = response.data;
    return resp;
  }
  catch (err) {
    if (err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
      err.message === "La autorización ha caducado, intenta de nuevo")
      window.location.reload();
    return Promise.reject((err.message && err) || 'Something went wrong');
  }
}

export const postConexion = async (urlApi, objeto) => {
  try {
    let url = initData.api + urlApi;
    const response = await axios.post(url, objeto).catch(err => {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            if (err.response.data.message === "Token does not exists.")
              throw new Error(`No hay permiso para actualizar tu contraseña`);
            else
              throw new Error(`Tu correo o contraseña son incorrectos`);
          case 403:
            if (err.response.data.message === "This code is already expired by Admin!")
              throw new Error(`Tu código no es valido`);
            else {
              localStorage.setItem("accessToken", null);
              throw new Error(`La autorización ha caducado, intenta de nuevo`);
            }
          case 401:
            const originalRequest = err.config;
            let refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken)
              return axios
                .post(initData.api + "users/token/refresh", { refreshToken: refreshToken })
                .then((res) => {
                  if (res.status === 200) {
                    localStorage.setItem("accessToken", res.data.accessToken);
                    return axios(originalRequest);
                  }
                });
            break;
          case 404:
            throw new Error(`Su correo o contraseña son incorrectos`);
          case 409:
            if (err.response.data.message === "Email address not valid")
              throw new Error(`Correo electrónico no válido`);
            else
              throw new Error(`El usuario ya poseé una cuenta`);
          case 430:
            throw new Error(`Su último pago no es válido o no tienes datos de pago registrados. `);
          case 500:
            if (err.response.data.message === "Maybe the user is disabled.")
              throw new Error(`Tu cuenta se encuentra desactivada`);
            else if (err.response.data.message.indexOf("Error generating Word document with exception") > -1)
              throw new Error(`Revisa los campos e intenta de nuevo`);
            else
              throw new Error(`Por favor intente de nuevo, más tarde`);
          default:
            throw new Error(`La api responde pero con error ${err.response.status}`);
        }
      }
      else if (err.request) {
        throw new Error(`No existe conexión`);
      }
      else {
        throw err;
      }
    });
    const resp = response.data;
    return resp;
  }
  catch (err) {
    if (err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
      err.message === "La autorización ha caducado, intenta de nuevo")
      window.location.reload();
    return Promise.reject((err.message && err) || 'Something went wrong');
  }
}

export const postConexionFile = async (urlApi, file, type?) => {
  try {
    let url = initData.api + urlApi;
    let formData = new FormData();
    formData.append("file", file[0]);
    if (type)
      formData.append("type", type);
    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).catch(err => {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            throw new Error(`Su correo o contraseña son incorrectos`);
          case 401:
            const originalRequest = err.config;
            let refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken)
              return axios
                .post(initData.api + "users/token/refresh", { refreshToken: refreshToken })
                .then((res) => {
                  if (res.status === 200) {
                    localStorage.setItem("accessToken", res.data.accessToken);
                    return axios(originalRequest);
                  }
                });
            break;
          case 403:
            localStorage.setItem("accessToken", null);
            throw new Error(`La autorización ha caducado, intenta de nuevo`);
          case 404:
            throw new Error(`Su correo o contraseña son incorrectos`);
          case 409:
            throw new Error(`El usuario ya poseé una cuenta`);
          case 500:
            throw new Error(`Por favor intente de nuevo, más tarde`);
          default:
            throw new Error(`La api responde pero con error ${err.response.status}`);
        }
      }
      else if (err.request) {
        throw new Error(`No existe conexión`);
      }
      else {
        throw err;
      }
    });
    const resp = response.data;
    return resp;
  }
  catch (err) {
    if (err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
      err.message === "La autorización ha caducado, intenta de nuevo")
      window.location.reload();
    return Promise.reject((err.message && err) || 'Something went wrong');
  }
}

export const getConexion = async (urlApi: string, headers: string = "") => {
  try {
    let url = initData.api + urlApi;
    const response = await axios.get(url).catch(err => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            const originalRequest = err.config;
            let refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken)
              return axios
                .post(initData.api + "users/token/refresh", { refreshToken: refreshToken })
                .then((res) => {
                  if (res.status === 200) {
                    localStorage.setItem("accessToken", res.data.accessToken);
                    return axios(originalRequest);
                  }
                });
            break;
          case 403:
            localStorage.setItem("accessToken", null);
            throw new Error(`La autorización ha caducado, intenta de nuevo`);
          case 404:
            throw new Error(`Servicio no encontrado`);
          case 409:
            throw new Error(`El usuario ya poseé una cuenta`);
          case 500:
            if (err.response.data.message === "Probably the document was disbled by admin.")
              throw new Error(`El documento se encuentra desactivado.`);
            else
              throw new Error(`Por favor intente de nuevo, más tarde`);
          default:
            localStorage.setItem("accessToken", null);
            throw new Error(`La api responde pero con error ${err.response.status}`);
        }
      }
      else if (err.request) {
        throw new Error(`No existe conexión`);
      }
      else {
        throw err;
      }
    });
    const resp = response.data;
    return resp;
  }
  catch (err) {
    if (err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
      err.message === "La autorización ha caducado, intenta de nuevo")
      window.location.reload();
    return Promise.reject((err.message && err) || 'Something went wrong');
  }
}

export const putConexion = async (urlApi, objeto) => {
  try {
    let url = initData.api + urlApi;
    const response = await axios.put(url, objeto).catch(err => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            const originalRequest = err.config;
            let refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken)
              return axios
                .post(initData.api + "users/token/refresh", { refreshToken: refreshToken })
                .then((res) => {
                  if (res.status === 200) {
                    localStorage.setItem("accessToken", res.data.accessToken);
                    return axios(originalRequest);
                  }
                });
            break;
          case 403:
            localStorage.setItem("accessToken", null);
            throw new Error(`La autorización ha caducado, intenta de nuevo`);
          case 404:
            throw new Error(`Servicio no encontrado`);
          case 409:
            throw new Error(`El usuario ya poseé una cuenta`);
          case 500:
            throw new Error(`Por favor intente de nuevo, más tarde`);
          default:
            throw new Error(`La api responde pero con error ${err.response.status}`);
        }
      }
      else if (err.request) {
        throw new Error(`No existe conexión`);
      }
      else {
        throw err;
      }
    });
    const resp = response.data;
    return resp;
  }
  catch (err) {
    if (err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
      err.message === "La autorización ha caducado, intenta de nuevo")
      window.location.reload();
    return Promise.reject((err.message && err) || 'Something went wrong');
  }
}

export const deleteConexion = async (urlApi, objeto) => {
  try {
    let url = initData.api + urlApi;
    const response = await axios.delete(url, { data: objeto }).catch(err => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            const originalRequest = err.config;
            let refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken)
              return axios
                .post(initData.api + "users/token/refresh", { refreshToken: refreshToken })
                .then((res) => {
                  if (res.status === 200) {
                    localStorage.setItem("accessToken", res.data.accessToken);
                    return axios(originalRequest);
                  }
                });
            break;
          case 403:
            localStorage.setItem("accessToken", null);
            throw new Error(`La autorización ha caducado, intenta de nuevo`);
          case 404:
            throw new Error(`Servicio no encontrado`);
          case 409:
            throw new Error(`El usuario ya poseé una cuenta`);
          case 500:
            throw new Error(`Por favor intente de nuevo, más tarde`);
          default:
            throw new Error(`La api responde pero con error ${err.response.status}`);
        }
      }
      else if (err.request) {
        throw new Error(`No existe conexión`);
      }
      else {
        throw err;
      }
    });
    const resp = response.data;
    return resp;
  }
  catch (err) {
    if (err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
      err.message === "La autorización ha caducado, intenta de nuevo")
      window.location.reload();
    return Promise.reject((err.message && err) || 'Something went wrong');
  }
}