const objFromArrayPlans = (arr: any[]) =>
  arr.reduce((accumulator, current, index) => {
    let indexId = 4
    switch (current.namePlan) {
      case 'explorador':
        indexId = 0;
        break;
      case 'inicial':
        indexId = 1;
        break;
      case 'corporativo':
        indexId = 2;
        break;
      case 'premium':
        indexId = 3;
        break;
    }
    accumulator[indexId] = current;
    return accumulator;
  }, {});

export default objFromArrayPlans;