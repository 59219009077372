import React, { forwardRef } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 0,
    paddingBottom: 120
  },
  marginBox: {
    marginTop: '-33%',
    paddingLeft: '2%',
    [theme.breakpoints.only('md')]: {
      marginTop: '-33%',
      marginLeft: '4%'
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '-35%',
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: '-43%',
    }
  },
  fontTitle: {
    fontSize: '1.5rem',
    textAlign: 'left',
    lineHeight: '1.3',
  },
  statistics: {
    textAlign: 'center'
  },
  logoImage: {
    perspectiveOrigin: 'center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}));

const Hero: FC<any> = forwardRef(({ className, ...rest }, ref) => {
  const classes = useStyles();
  const settings = window.localStorage.getItem('settings') || 'LIGHT';

  return (
    <div
      ref={ref}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <div >
        <img
          title="Arupo hub decoración fondo"
          alt="Arupo hub decoración fondo"
          className={classes.logoImage}
          src={settings.includes("ONE_DARK") ? "/static/images/arupo_logos/FONDO_dark.svg" : "/static/images/arupo_logos/FONDO_light.svg"}
        />
      </div>
      <Container maxWidth="xl"
        className={classes.marginBox}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Box mt={10} pl={6}>
                <Typography
                  component="h1"
                  variant="overline"
                  color="secondary"
                  className={classes.fontTitle}
                >
                  Empieza la Transformación digital de tu negocio
                </Typography>
              </Box>
              <Box mt={2} pl={6}>
                <Typography
                  component="h2"
                  variant="body1"
                  color="textSecondary"
                >
                  Automatiza tus procesos y la prestación de tus servicios. Crea, automatiza y genera documentos con un método NO CODE de forma ágil y fácil.
                  Incrementa tu productividad y ofrece experiencias increíbles a tus clientes.
                </Typography>
              </Box>
              <Box mt={3} ml={4}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Grid className={classes.statistics} item md={4}>
                    <Typography
                      component="p"
                      variant="h2"
                      color="secondary"
                    >
                      80%&nbsp;+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      TIEMPO
                    </Typography>
                  </Grid>
                  <Grid className={classes.statistics} item md={4}>
                    <Typography
                      component="p"
                      variant="h2"
                      color="secondary"
                    >
                      90%&nbsp;-
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      ERRORES
                    </Typography>
                  </Grid>
                  <Grid className={classes.statistics} item md={4}>
                    <Typography
                      component="p"
                      variant="h2"
                      color="secondary"
                    >
                      99%&nbsp;+
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      EFICIENCIA
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
          >
            <Box position="relative">
              <div className={classes.shape}>
                <img
                  title="Arupo hub decoración figuras"
                  alt="Arupo hub decoración figuras"
                  src="/static/home/shapes.svg"
                />
              </div>
              <div className={classes.image}>
                <img
                  title="Arupo hub demo dashboard"
                  alt="Arupo hub demo dashboard"
                  src="/static/home/dark-light.png"
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
});

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;