import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { getConexion } from 'src/contexts/Conexion';
import objFromArrayPlans from 'src/utils/objFromArrayPlans';

const initialState = {
  isLoaded: false,
  plansList: {
    byId: {},
    allIds: []
  }
};

const slice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    getPlans(state, action) {
      const board = action.payload;
      state.plansList.byId = objFromArrayPlans(board);
      state.plansList.allIds = Object.keys(state.plansList.byId).map(Number);
      state.isLoaded = true;
    }
  }
});

export const reducer = slice.reducer;

export const getPlans = () => async (dispatch) => {
  const response = await getConexion("users/getAllPlans");
  dispatch(slice.actions.getPlans(response));
};

export default slice;