import React, { useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  IconButton,
  Button,
  Divider,
  Toolbar,
  Hidden,
  SvgIcon,
  Link,
  makeStyles
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import useAuth from 'src/hooks/useAuth';
import Logo from 'src/components/Logo';
import LogoDark from 'src/components/LogoDark';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const TopBar: FC<TopBarProps> = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth();
  const settings = window.localStorage.getItem('settings') || 'LIGHT';
  const [labelLogin] = useState<string>(isAuthenticated ? "Sandbox" : "Login");

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
          <Box flexGrow={1} />
          <Button
            className={classes.link}
            color="secondary"
            component={RouterLink}
            to="/register"
            variant="contained"
            size="small"
          >
            Empecemos
          </Button>
          <Divider className={classes.divider} />
          <Link
            className={classes.link}
            color="secondary"
            component={RouterLink}
            to="/app"
            underline="none"
            variant="body2"
          >
            {labelLogin}
          </Link>
        </Hidden>
        <Hidden mdDown>
          <Link href="#home">
            {settings.includes("LIGHT") ? <Logo className={classes.logo} /> : <LogoDark className={classes.logo} />}
          </Link>
          <Link
            className={classes.link}
            color="textSecondary"
            href="#explora"
            underline="none"
            variant="body2"
          >
            Explora
          </Link>
          <Link
            className={classes.link}
            color="textSecondary"
            href="#herramientas"
            underline="none"
            variant="body2"
          >
            Herramientas
          </Link>
          <Box flexGrow={1} />
          <Link
            className={classes.link}
            color="textSecondary"
            href="#precios"
            underline="none"
            variant="body2"
          >
            Precios
          </Link>
          <Link
            className={classes.link}
            color="textSecondary"
            href="#desarrollador"
            underline="none"
            variant="body2"
          >
            Desarrollador
          </Link>
          <Button
            className={classes.link}
            color="secondary"
            component={RouterLink}
            to="/register"
            variant="contained"
            size="small"
          >
            Empecemos
          </Button>
          <Divider className={classes.divider} />
          <Link
            className={classes.link}
            color="secondary"
            component={RouterLink}
            to="/app"
            underline="none"
            variant="body2"
          >
            {labelLogin}
          </Link>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => { }
};

export default TopBar;