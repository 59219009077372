export const APP_VERSION = '2.0.02092024';

export const MASTER_ACCOUNT = 'info@arupo.io';

// export const REDIRECTION_DATAWEB = 'http://localhost:3000/';
// export const REDIRECTION_DATAWEB = 'https://staging.arupo.io/';
export const REDIRECTION_DATAWEB = 'https://arupo.io/';

// For testing
// export const DATAWEB = 'https://eu-test.oppwa.com';
export const DATAWEB = 'https://eu-prod.oppwa.com';

export const ENABLE_REDUX_DEV_TOOLS = true;

// test=>000.100.112
// prod=>000.000.000
// export const DATAFAST_SUCCESS_CODE = '000.100.112'
export const DATAFAST_SUCCESS_CODE = '000.000.000'

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK'
};